import { EVENT_NAME } from './event-name';
import { EventTrackParams } from './types';

export { EVENT_NAME };

/**
 * 事件埋点统一触发方法
 * @param eventName - 事件名称
 * @param data - 事件参数
 */
export const EVENT_TRACK = async <T extends EVENT_NAME>(eventName: T, data?: EventTrackParams<T>) => {
  const todoArr = ['InviteFriendsActivityView', 'InviteFriendsActivityBtnClick', 'RegisteInvitationSend'];
  // TODO InviteFriendsActivityView 在某些 windows 设备下无法触发，本地和 mac 电脑正常（不知道是环境原因还是埋点SDK原因故）暂时不上报，待开发和测试验证原因
  if (todoArr.includes(eventName)) {
    return;
  }

  try {
    const sensors = await window.loadSensors;
    sensors.track(eventName, data);
  } catch (e) {
    console.log('EVENT_TRACK error:', e);
  }
};
export const SENSORS_LOGIN = async (userId: string) => {
  try {
    const sensors = await window.loadSensors;
    sensors.login(userId);
  } catch (e) {
    console.log('SENSORS_LOGIN error:', e);
  }
};
export const SENSORS_LOGOUT = async () => {
  try {
    const sensors = await window.loadSensors;
    sensors.logout();
  } catch (e) {
    console.log('SENSORS_LOGIN error:', e);
  }
};
